<template>
    <a-modal :visible='visible' :title="detail.id ? '编辑' : '新增' + '演练记录'" :footer='null' @cancel='cancel' width='640px'>

      <a-form
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 14 }"
        :colon="false"
        @submit="handleSubmit"
        :form="form"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="演练名称">
              <a-input
                v-decorator="['name', 
                  {
                    initialValue: detail.name,
                     rules: [{ required: true, message: '请输入' }] },]"
              />
            </a-form-item>
          </a-col>

                 <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="演练时间">
                      <a-date-picker
          style="width: 100%"
                    v-decorator="['time', 
                  { 
                    initialValue: detail.time ? moment( detail.time) : undefined,
                    rules: [{ required: true, message: '请选择' }] },]"
          placeholder=""
        />

            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="地点" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
              <a-textarea
                v-decorator="[
                  'location',
                  { 
                    initialValue: detail.location,
                    rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>

                    <a-col :lg="12" :md="12" :sm="24">
         <a-form-item label="应急预案" >
              <Upload type='file' :path='plan' @change="(payload) => (plan = payload)" />
            </a-form-item>
          </a-col>
                              <a-col :lg="12" :md="12" :sm="24">
         <a-form-item label="记录表" >
              <Upload type='file' :path='recordSheet' @change="(payload) => (recordSheet = payload)" />
            </a-form-item>
          </a-col>
                         <a-col :lg="12" :md="12" :sm="24">
         <a-form-item label="演练照片" >
              <Upload :path='picture' @change="(payload) => (picture = payload)" />
            </a-form-item>
          </a-col>

        </a-row>

        <div class="footer right">
          <a-space>
            <a-button @click="cancel">关闭</a-button>
            <a-button htmlType="submit" type="primary" :loading="loading">
              {{ detail.id ? '编辑' : '新增' }}
            </a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
</template>

<script>
import request from "@/api/request";
import Upload from './upload.vue'
import moment from 'moment'


function add(data) {
  return request({
    url: '/office-service/quality/plan/rehearsal/add',
    method: 'post',
    data,
  });
}

function edit(data) {
  return request({
    url: '/office-service/quality/plan/rehearsal/modify',
    method: 'post',
    data,
  });
}

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Object,
      default: () => ({})
    }
  },

  components: {
    Upload,
  },

  watch: {
    visible() {
      if (this.visible) {
        this.plan = this.detail.plan;
        this.recordSheet = this.detail.recordSheet,
        this.picture = this.detail.picture
      }
    }
  },

  data() {
    return {
         form: this.$form.createForm(this),
      loading: false,
      plan: '',
      recordSheet: '',
      picture: '',
    };
  },


  methods: {
    moment,
    cancel() {
      this.$emit('cancel')
          this.form.resetFields();
          this.plan = '';
          this.recordSheet = '';
          this.picture = '';
    },
    refresh() {
      this.$emit('refresh');
      this.cancel();
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {

          if (!this.plan) {
               this.$message.error("请上传应急预案");
               return;
          }
          this.loading = true;

          let task = add;
          if (this.detail.id) {
            task = edit;
          }
          task({
            ...this.detail,
            ...values,
            time: values.time.format("YYYY-MM-DD"),
            plan: this.plan,
            recordSheet: this.recordSheet,
            picture: this.picture,
          }).then(() => {
            this.refresh();
          }).finally(() => {
            this.loading = false;
          })
        }
      });
    },
  }
};
</script>
