<template>
    <a-modal :visible='visible' title="详情" :footer='null' @cancel='cancel' width='640px'>

          <a-descriptions bordered size="small" :column="2">
    <a-descriptions-item>
      <div slot="label" class="center">演练名称</div>
      <div class="">
        {{ detail.name }}
      </div>
    </a-descriptions-item>
        <a-descriptions-item>
      <div slot="label" class="center">演练时间</div>
      <div class="">
        {{ detail.time }}
      </div>
    </a-descriptions-item>

            <a-descriptions-item :span='2'>
      <div slot="label" class="center">地点</div>
      <div class="">
        {{ detail.location }}
      </div>
    </a-descriptions-item>

            <a-descriptions-item :span='2'>
      <div slot="label" class="center">应急预案</div>
      <div class="">
        <a :href="plan">{{$getFileName(plan)}}</a>
      </div>
    </a-descriptions-item>

                <a-descriptions-item :span='2'>
      <div slot="label" class="center">记录表</div>
      <div class="">
        <a :href="recordSheet">{{$getFileName(recordSheet)}}</a>
      </div>
    </a-descriptions-item>
               <a-descriptions-item :span='2'>
      <div slot="label" class="center">演练照片</div>
      <div class="">
        <a :href="picture" v-if="picture">
            <img :src="picture" style="width: 120px;border-radius: 4px" alt="">
            </a>
      </div>
    </a-descriptions-item>
  </a-descriptions>


    </a-modal>
</template>

<script>

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Object,
      default: () => ({})
    }
  },

  watch: {
    visible() {
      if (this.visible) {
        this.plan = this.detail.plan;
        this.recordSheet = this.detail.recordSheet,
        this.picture = this.detail.picture
      }
    }
  },

  data() {
    return {
      plan: '',
      recordSheet: '',
      picture: '',
    };
  },


  methods: {
    cancel() {
      this.$emit('cancel')
          this.plan = '';
          this.recordSheet = '';
          this.picture = '';
    },

  }
};
</script>
