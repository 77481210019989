<template>
  <div>
    <Pane />

    <a-card class="container">

      <a-row>
        <a-col :span='22'>
      <a-form-model :colon="false" layout="inline">
        <a-form-model-item>
          <a-input v-model="form.name" placeholder="演练名称" style="width: 150px"></a-input>
        </a-form-model-item>
               <a-form-model-item>
                   <a-range-picker
              :placeholder="['演练开始时间', '演练结束时间']"
              v-model="form.date"
              :allowClear="true"
            />
        </a-form-model-item>
                <a-form-model-item>
                             <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
        </a-form-model-item>
      </a-form-model>
        </a-col>

        <a-col :span='2'>
            <div class="right" style="margin-top: 4px">
                <a-button type='primary'
                v-if="$getPermission($route.path + '/add')"
                 @click="add">新增</a-button>
            </div>
        </a-col>
      </a-row>

      <Padding />

      <a-table bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        rowKey='id'

      >
                <a-table-column title="序号" width="60px" align="center" fixed="left">
          <template slot-scope="text, row, index">
            {{ index + 1 }}
          </template>
        </a-table-column>
        <a-table-column title="演练名称" data-index="name" />
        <a-table-column title="演练时间" data-index="time" align="center" />
        <a-table-column title="地点" data-index="location" align="center" />
        <a-table-column title="记录人" data-index="creatorName" align="center" />
        <a-table-column title="记录时间" data-index="createAt" align="center" />

        <a-table-column title="操作" align="center">
          <template slot-scope="text">
            <a-space>
              <a href="#"
                 v-if="$getPermission($route.path + '/edit')"
               @click.prevent="edit(text)">编辑</a>
              <a href="#" 
              @click.prevent="openDetail(text)">查看</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>

        <Editor :visible='visible' :detail='detail' @cancel='cancel' @refresh='reset' />
        <Detail :visible='detailVisible' :detail='detail' @cancel='cancelDetail' />
  </div>
</template>

<script>
import Editor from './components/editor.vue'
import Detail from './components/detail.vue'

import watermark from "@/mixins/watermark";
import request from "@/api/request";

function fetchList(data) {
  return request({
    url: '/office-service/quality/plan/rehearsal/listPage',
    method: 'post',
    data,
  });
}

export default {
  mixins: [watermark],

  components: {
    Editor,
    Detail,
  },

  data() {
    return {
        form: {},
      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      visible: false,
      detail: {},
      detailVisible: false,
    };
  },



  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      const { current, pageSize, form } = this;

      let startTime, endTime;

        if (Array.isArray(form.date) && form.date.length === 2) {
            startTime = form.date[0].format("YYYY-MM-DD");
            endTime = form.date[1].format("YYYY-MM-DD");
          }

      fetchList({
                pageNum: current,
        pageSize,
        ...form,
        date: undefined,
        startTime,
        endTime,
      })
        .then(res => {
            if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.pageSize = 10;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.pageSize = 10;
      this.getList();
    },
    add() {
        this.visible = true;
    },

    edit(text) {
        this.detail = text;
        this.visible = true;
    },
    cancel() {
        this.visible = false;
        this.detail = {}
    },

    openDetail(text) {
        this.detail = text;
        this.detailVisible = true;
    },
    cancelDetail() {
        this.detail = {}
        this.detailVisible = false;
    }
  }
};
</script>
