<template>
  <div>
    <input
      ref="upload"
      style="display: none"
      type="file"
      :multiple="false"
      @change="onChange"
    />

      <div v-if="type==='image'" class="upload" @click="onClick">
        <img v-if="path" :src="path" />
        <a-icon v-else type="plus" class="icon" />
      </div>
      <a-button v-else @click="onClick">
        {{ path ? '重新上传' : '点击上传' }}
      </a-button>
  </div>
</template>

<script>
import { uploadFile } from "@/api/upload";
export default {
  props: {
    type: {
        type: String,
        default: 'image',
    },
    path: {
      type: String,
      default: ""
    }
  },
  methods: {
    onClick() {
      this.$refs.upload.click();
    },

    onChange(e) {
      const files = e.target.files;
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append("files", files[i]);
      }

      const hide = this.$message.loading("上传中...", 0);

      uploadFile(data)
        .then(res => {
          console.log("upload res", res);
          if (Array.isArray(res) && res.length > 0) {
            this.$emit("change", res[0].completePath);
          }
        })
        .finally(() => {
          hide();
          e.target.value = null;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.upload {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  .icon {
    font-size: 24px;
    color: #999;
  }
  img {
    border-radius: 2px;
    display: block;
    object-fit: cover;
    cursor: pointer;
    height: 76px;
    width: 76px;
  }
}
</style>